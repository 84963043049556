import React from 'react'
import useSiteMetadata from '../hooks/use-site-metadata'
import { Helmet } from 'react-helmet'
import createDocumentTitle from '../lib/document-title'
import {
  Button,
  Code,
  FieldWrap,
  Icon,
  Link,
  TextField,
  Toast,
  Tooltip,
} from '@deque/cauldron-react'
import Header from '../components/Header'
import Logo from '../components/Header/Logo'
import SiteSearch from '../components/Search/SiteSearch'
import ContactUs from '../components/Header/ContactUs'
import Footer from '../components/Footer'
import dedent from 'dedent'
import CopyToClipboard from 'react-copy-to-clipboard'
import './agora-access.scss'
import { Buffer } from 'buffer/'

export default function () {
  const { title: siteTitle } = useSiteMetadata()
  const pageTitle = 'Configuring Access to Agora packages'
  const apiKeyHelpTextId = 'api-key-desc'

  const emailRef = React.useRef<HTMLInputElement>(null)
  const apiKeyRef = React.useRef<HTMLInputElement>(null)
  const copyContentRef = React.useRef<HTMLButtonElement>(null)
  const npmContentSectionRef = React.useRef<HTMLDivElement>(null)

  const [emailError, setEmailError] = React.useState<string | null>(null)
  const [apiKeyError, setApiKeyError] = React.useState<string | null>(null)
  const [showCopyContentToast, setShowCopyContentToast] = React.useState(false)
  const [configurationContent, setConfigurationContent] = React.useState<
    string | null
  >(null)

  React.useEffect(() => {
    if (configurationContent && npmContentSectionRef.current) {
      npmContentSectionRef.current.focus()
    }
  }, [configurationContent])

  const handleClickGenerate = () => {
    const email = emailRef.current?.value
    const apiKey = apiKeyRef.current?.value

    // Render all appropriate errors
    setEmailError(email ? null : 'Please enter your email')
    setApiKeyError(
      apiKey ? null : 'Please enter either your API key or identity token'
    )

    // Focus on the first error
    if (!email) {
      emailRef.current?.focus()
    } else if (!apiKey) {
      apiKeyRef.current?.focus()
    }

    // If there are no errors, generate the configuration content
    if (!email || !apiKey) {
      setConfigurationContent(null)
    } else {
      const buffer = Buffer.from(`${email}:${apiKey}`)
      const authToken = buffer.toString('base64')
      setConfigurationContent(dedent`
        @axe-devtools:registry=https://agora.dequecloud.com/artifactory/api/npm/devtools-npm/
        //agora.dequecloud.com/artifactory/api/npm/devtools-npm/:_auth="${authToken}"
        //agora.dequecloud.com/artifactory/api/npm/devtools-npm/:email=${email}
      `)
    }
  }

  return (
    <>
      <Helmet>
        <title>{createDocumentTitle(siteTitle, pageTitle)}</title>
      </Helmet>
      <Header centerAlign>
        <div className="cluster--left">
          <Logo />
        </div>
        <div className="cluster--right">
          <SiteSearch />
          <ContactUs />
        </div>
      </Header>
      <div className="Content center-align">
        <Toast
          type="confirmation"
          show={showCopyContentToast}
          onDismiss={() => {
            setShowCopyContentToast(false)
            copyContentRef.current?.focus()
          }}
        >
          <span>Copied configuration to clipboard!</span>
        </Toast>
        <div>
          <h1>{pageTitle}</h1>
          <p>
            Agora is a private NPM registry for Deque customers. It hosts the{' '}
            <code>@axe-devtools</code> NPM packages. Use the following steps to
            configure your system to access these packages.
          </p>
          <div>
            <FieldWrap>
              <p>
                <strong>Step 1:</strong>{' '}
                <span>
                  Supply the following information associated with your Agora
                  account. None of the information you enter will be stored or
                  transmitted.
                </span>
              </p>
              <TextField
                label="Email address"
                required
                fieldRef={emailRef}
                error={emailError}
              />
              <TextField
                label="Agora API key or Identity Token"
                required
                fieldRef={apiKeyRef}
                error={apiKeyError}
                aria-describedby={apiKeyHelpTextId}
              />
              <p className="note" id={apiKeyHelpTextId}>
                <span>
                  NOTE: Your API key, and identity tokens, can be found on your
                  <Link
                    target="_blank"
                    href="https://agora.dequecloud.com/ui/admin/artifactory/user_profile"
                  >
                    user profile page
                  </Link>
                  in Agora.
                </span>
              </p>
              <Button onClick={handleClickGenerate}>
                <span>Generate Configuration</span>
              </Button>
              {configurationContent && (
                <div tabIndex={-1} ref={npmContentSectionRef}>
                  <p>
                    <strong>Step 2:</strong> Paste the following into your{' '}
                    <code>~/.npmrc</code> file (or{' '}
                    <code>%USERPROFILE%/.npmrc</code> on Windows):
                  </p>
                  <div className="copyable-code">
                    <Code language="yaml" scrollable="true">
                      {configurationContent}
                    </Code>
                    <CopyToClipboard
                      text={configurationContent}
                      onCopy={() => setShowCopyContentToast(true)}
                    >
                      <Button
                        className="copy-button"
                        variant="secondary"
                        buttonRef={copyContentRef}
                        thin
                      >
                        <Icon
                          type="copy"
                          label="Copy configuration to clipboard"
                        />
                      </Button>
                    </CopyToClipboard>
                    <Tooltip target={copyContentRef}>
                      Copy configuration to clipboard
                    </Tooltip>
                  </div>
                  <p>
                    You should now be able to install NPM packages using the{' '}
                    <code>@axe-devtools</code> namespace.
                  </p>
                </div>
              )}
            </FieldWrap>
          </div>
        </div>
      </div>
      <Footer centerAlign />
    </>
  )
}
